/* .dds__tabs__more {
	display: none !important;
	}
.dds__tabs__tab--hidden {
	position: inherit !important;
	left: inherit !important;
	top: inherit !important;
	visibility: inherit !important;
	} */
/* .dds___tabs-vertical, .dds__tabs {
	display: flex !important;
	flex-direction: column !important;
	border-bottom: none !important;
	max-width: none !important;
	margin-right: 0 !important;
	width: 100% !important;
	} */
/* 
.dds__tabs .dds__nav {
	display:block !important;
} */

.dds__tabs__more[aria-selected=true], .dds__tabs__tab[aria-selected=true] {
	color: var(--blue-600) !important
}