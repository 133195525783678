@media (max-width: 768px) {
	.content-to-hide {
		display: none;
	}
}

@media (max-width: 576px) {
	.content-to-align {
		text-align: center !important;
	}
}
