@media (max-width: 768px) {
	.content-to-hide {
		display: none;
	}
}

.faqicon{
    width:40px;
    /* display:block; */
    /* height:20px; */
    /* background:black; */
    /* color:white; */
    text-decoration:none;
    /* padding-left:20px; */
}
.faqicon:before{
    content: '';
    background:url('../../Assets/FAQs.svg');
    background-size:cover;
        position:absolute;
    width:26px; 
    height:20px;
    /* margin-right:20px; */
}