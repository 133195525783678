.custom_admin_icon::before{
    content: url('./../user_admin_icon.svg');
}

.icon--announcement {
	content: url(./../announcement_icon.svg);
}

.icon--unauth {
	content: url(./../Unauth_icon.svg);
}

.icon--vdi {
	content: url(./../vdi_icon.svg);
}

.icon--assetTracker {
	content: url('./../Asset Tracker Icon.svg');
}

.icon--totalAssets {
	content: url('./../total_assets.svg');
}

.icon--EOL::before {
	content: url('./../EOL Icon.svg');
}
.icon--manageUsers {
	content: url('./../Manage\ Admin.svg')
}